import React, { useState } from "react";
import Copyright2 from "components/footerpt2/Copyright2";
import Alert, { AlertColor } from "@mui/lab/Alert";
import CustomAlert from "components/alert/CustomAlert";
import { host } from "config";
import "./OptiEnhance.css";
import CircularProgress from "@mui/material/CircularProgress";

interface FormData {
  groupId: string;
  memberId: string;
  firstName: string;
  lastName: string;
  email: string;
}

const OptiEnhance: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    groupId: "",
    memberId: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  const [message, setMessage] = useState<string | null>(null);

  const [alertType, setAlertType] = useState<AlertColor | undefined>(undefined);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "groupId" && !/^\d*$/.test(value)) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Make the API call to submit the data
    setIsLoading(true);
    try {
      const response = await fetch(`${host}/api/enrollments/vba/optiEnhanceEnrollments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsLoading(false);
        setAlertMessage(
          "Thank you for your submission. You should receive an email within the next 24-48 hours on how to access your products online and begin using them."
        );
        setAlertOpen(true);
        setMessage("Data submitted successfully");
        setAlertType("success");
        setFormData({
          groupId: "",
          memberId: "",
          firstName: "",
          lastName: "",
          email: "",
        });
      } else {
        setIsLoading(false);
        setMessage("Error submitting data: " + (await response.text()));
        setAlertType("error");
        console.log("Error submitting data", await response.text());
      }
    } catch (error) {
      setIsLoading(false);
      setMessage("There was an error submitting the data");
      setAlertType("error");
      console.error("There was an error submitting the data", error);
    }
    //setIsLoading(false);
  };

  return (
    <div className="OptiEnhance">
      <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
      <div className="FORCONTAINTEROPTI">
        <form className="form-Opti" onSubmit={handleSubmit}>
          <h2>OptiEnhance Member Registration Portal </h2>
          <p>
            As part of our OptiMedHealth Member benefits you are eligible for our OptiEnhance Products. You can find the
            list of products in your Employer Enrollment Guide provided by OptiMedHealth.
          </p>
          <p>
            In order to be setup in our system please complete the information below. Once you complete the information
            below you will receive an email with the steps necessary to complete the enrollment process and begin using
            your OptiEnhance Products.
          </p>
          <p>Should you have any questions please contact us at 800-842-8770.</p>
          <label>
            Please enter the group number found on your OptiMedHealth Benefit Card
            <input name="groupId" placeholder="" value={formData.groupId} onChange={handleInputChange} required />
          </label>
          <label>
            Please enter your member number found on your OptiMedHealth Benefit Card
            <input name="memberId" placeholder="" value={formData.memberId} onChange={handleInputChange} required />
          </label>
          <label>
            First Name
            <input name="firstName" placeholder="" value={formData.firstName} onChange={handleInputChange} required />
          </label>
          <label>
            Last Name
            <input name="lastName" placeholder="" value={formData.lastName} onChange={handleInputChange} required />
          </label>
          <label>
            Email Address
            <input
              name="email"
              type="email"
              placeholder=""
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
      {message && <Alert severity={alertType}>{message}</Alert>}
      <div className="copyright-fixed23">{/* <Copyright2 /> */}</div>
    </div>
  );
};

export default OptiEnhance;
