import React, { useContext, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { app } from "../../config";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import "font-awesome/css/font-awesome.min.css";
import "./SideNavBar.css";
import pic from "./step-1.png";

interface Props {
  isOpen: boolean;
}

const auth = getAuth();

const SideNavBar: React.FC<Props> = ({ isOpen }) => {
  const [activeDropdown, setActiveDropdown] = useState<Record<string, boolean>>({});

  const authContext = useContext(AuthContext); // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, role } = authContext; // Get current user from Auth context

  //const navigate = useNavigate(); // Hook to navigate between routes

  const navigate = useNavigate(); // Hook to navigate between routes

  // Function to navigate to the upload page
  const goToUploadPage = () => {
    navigate("/upload");
  };

  const goToEmploymentEnrollment = () => {
    navigate("/enroll");
  };
  // Function to navigate to the admin portal
  const goToAdminPortal = () => {
    navigate("/adminPanel");
  };

  const goToClaims = () => {
    navigate("/claims");
  };

  const goToClaimsLookup = () => {
    navigate("/claimsLookup");
  };

  const goToEmployeeMaintenance = () => {
    navigate("/employeeMaintenance");
  };

  const goToCensus = () => {
    navigate("/census");
  };

  const goToInvoice = () => {
    navigate("/invoice");
  };

  const goToOptienhancReg = () => {
    navigate("/optienhanceReg");
  };

  const goToDashboard = () => {
    navigate("/basePage");
  };

  const goToHome = () => {
    navigate("/");
  };

  const goToSignup = () => {
    navigate("/signup");
  };

  const goToLogin = () => {
    navigate("/login");
  };

  const goToAcctProfile = () => {
    navigate("/acctProfile");
  };

  const goToReports = () => {
    navigate("/commissions");
  };

  // const goToClaimsCommission = () => {
  //   navigate("/claimsCommission");
  // };

  const goToPaymentsPage = () => {
    navigate("/payments");
  };

  const goToOptienhancePage = () => {
    navigate("/optienhance");
  };

  const goToGapPage = () => {
    navigate("/gapPage");
  };

  const goToimplementationStatus = () => {
    navigate("/implementationStatus");
  };

  const toggleDropdown = (id: string) => {
    setActiveDropdown((prevActiveDropdown) => ({
      ...prevActiveDropdown,
      [id]: !prevActiveDropdown[id],
    }));
  };

  return (
    <div className={`side-nav-bar ${isOpen ? "open" : ""}`}>
      <div className="image-container">
        <img src={pic} alt="description" className="brand-image2" />
      </div>

      {currentUser ? (
        <>
          {(role === "employer" || role === "broker" || role === "internal_ugp" || role === "admin") && (
            <>
              <a href="#" onClick={() => toggleDropdown("employer")}>
                Employer <i className="fa fa-briefcase icon-right" aria-hidden="true"></i>
              </a>
              <div className={`dropdown-content ${activeDropdown["employer"] ? "show" : ""}`}>
                <a onClick={goToEmployeeMaintenance}>Employee Maintenance</a>
                <a onClick={goToClaimsLookup}>Claims Lookup</a>
                <a onClick={goToEmploymentEnrollment}>Enroll Employee</a>
                {/* <a onClick={goToDashboard}>Invoices</a> */}
                {/* <a onClick={goToDashboard}>Document Manager</a> */}
                <a onClick={goToCensus}>Download Census</a>
                {/* <a onClick={goToDashboard}>Upload an Enrollment Sheet</a> */}
              </div>
            </>
          )}

          {(role === "broker" || role === "internal_ugp" || role === "admin") && (
            <>
              <a href="#" onClick={() => toggleDropdown("broker")}>
                Broker <i className="fa fa-exchange icon-right" aria-hidden="true"></i>
              </a>
              <div className={`dropdown-content ${activeDropdown["broker"] ? "show" : ""}`}>
                {/* <a onClick={goToDashboard}>Home</a> */}
                {/* <a onClick={goToDashboard}>Your Commisions</a> */}
                <a onClick={goToClaims}>Submit a Claim</a>
                <a href="https://secure.optimedhealth.com/rating">View Online rating platform</a>
                <a onClick={goToInvoice}>Invoices</a>

                {/* <a onClick={goToCensus}>Census Template</a> */}
              </div>
            </>
          )}

          {role === "admin" && (
            <a href="#" onClick={() => toggleDropdown("admin")}>
              Admin <i className="fa fa-user-secret icon-right" aria-hidden="true"></i>
            </a>
          )}
          <div className={`dropdown-content ${activeDropdown["admin"] ? "show" : ""}`}>
            {role === "admin" && <a onClick={goToAdminPortal}>Admin Dashboard</a>}
          </div>

          <a href="#" onClick={() => toggleDropdown("other")}>
            Other <i className="fa fa-ellipsis-h icon-right" aria-hidden="true"></i>
          </a>
          <div className={`dropdown-content ${activeDropdown["other"] ? "show" : ""}`}>
            {/* <a onClick={goToDashboard}>Input Group ID</a> */}
            <a onClick={goToPaymentsPage}>Payments</a>
            <a onClick={goToOptienhancePage}>OptiEnhance</a>
            <a onClick={goToimplementationStatus}>Group Implementation</a>
            <a onClick={goToGapPage}>Gap Page</a>
            {/* <a onClick={goToReports}>Commissions</a> */}
            {/* <a onClick={goToInvoice}>Invoices</a> */}

            <a href="https://www.optimedhealth.com/contact/">Help</a>
          </div>
        </>
      ) : (
        <>
          <a href="/login" onClick={() => toggleDropdown("login")}>
            Please Login or Register to see options <i className="fa fa-user-secret icon-right" aria-hidden="true"></i>
          </a>

          <a href="#" onClick={() => toggleDropdown("other")}>
            Other <i className="fa fa-ellipsis-h icon-right" aria-hidden="true"></i>
          </a>
          <div className={`dropdown-content ${activeDropdown["other"] ? "show" : ""}`}>
            {/* <a onClick={goToDashboard}>Input Group ID</a> */}
            <a onClick={goToUploadPage}>Document Uploads</a>
            <a onClick={goToPaymentsPage}>Payments</a>
            <a onClick={goToOptienhancePage}>OptiEnhance</a>
            <a onClick={goToimplementationStatus}>Group Implementation</a>
            <a onClick={goToInvoice}>Invoices</a>
            {/* <a onClick={goToReports}>Commissions</a> */}
            <a onClick={goToOptienhancReg}>OptiEnhance Registration Check</a>
            <a href="https://www.optimedhealth.com/contact/">Help</a>
          </div>
        </>
      )}
      <div className={`dropdown-content ${activeDropdown["admin"] ? "show" : ""}`}></div>
    </div>
  );
};

export default SideNavBar;
